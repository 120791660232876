function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }
function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { parseSearchFiltersToUrl } from '~/apps/pu-links/domain/SearchUrl';
import { getLangFallbackUrl } from '~/lang/utils/langfetch';
import { getTransformObject } from '~/shared/photos/transforms';
import { getWKTPointFromGeoPoint } from '~/utility/geo/GeoLocation';
import { getPointFromStringOrDefault } from '~/utility/geo/latLng';
import { keysToCamel } from '~/utility/toCamel';
import { campsitePriceSearchResultSchema } from '../schemas';
export class FetchCampsitePriceSearchService {
  constructor(fetchOptions) {
    this.fetchOptions = fetchOptions;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetch(url, fetchOptions) {
    return $fetch(url, _objectSpread(_objectSpread({}, this.fetchOptions), fetchOptions));
  }
  search(langCode, filters, logger, fetchOptions) {
    var _this = this;
    return _asyncToGenerator(function* () {
      var params = _this.filtersToParams(filters);
      var gtmLogRequest = useGtmLogRequest('FetchCampsitePriceSearchService-search', logger);
      gtmLogRequest.markStart();
      var response = yield _this.fetch(getLangFallbackUrl(langCode, "/search2/price/?".concat(params.toString())), fetchOptions);
      gtmLogRequest.markEnd();
      return {
        campsites: _this.formatResults(keysToCamel(response.results)),
        pagination: {
          currentPage: Number(response.page),
          totalPages: response.num_pages,
          totalCount: response.count
        },
        title: response.title,
        heading: response.heading,
        description: response.description,
        hierarchyTrail: response.hierarchy_trail,
        bookable: response.bookable,
        bookableCount: response.bookable_count,
        category: response.category,
        count: response.count,
        // TODO: once added to response use it
        facetHierarchy: {},
        path: response.path,
        leadPrice: response.lead_price,
        sortOptions: response.sort_options,
        sortOption: response.sort || response.sort_field,
        maxPrice: response.result_max_price,
        within: response.within,
        breadcrumbs: response.breadcrumbs || []
      };
    })();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatResults(results) {
    return results.map(campsite => campsitePriceSearchResultSchema.parse(_objectSpread(_objectSpread({}, campsite), {}, {
      id: campsite.campsiteId.toString(),
      availableToSearch: true,
      status: 'bookable',
      hierarchyPath: campsite.path,
      hierarchyStringShort: campsite.hierarchyTextShort,
      hierarchyStringShortCountry: campsite.hierarchyTextShortCountry,
      primaryPhoto: campsite.primaryPhoto ? this.mapPrimaryPhoto(campsite.primaryPhoto) : undefined,
      rating: Number(campsite.rating),
      rating10: Number(campsite.rating) * 2,
      point: campsite.point ? getWKTPointFromGeoPoint(getPointFromStringOrDefault(campsite.point)) : undefined,
      nutshells: campsite.nutshells,
      distance: campsite.distance && Object.keys(campsite.distance).length ? campsite.distance : undefined
    })));
  }
  mapPrimaryPhoto(photo) {
    return {
      caption: photo.caption,
      url: _objectSpread(_objectSpread({}, getTransformObject('campsitePrimaryPhotoMaster', photo.url.masterImage)), {}, {
        masterImage: photo.url.masterImage
      })
    };
  }

  // tslint:disable-next-line cognitive-complexity
  filtersToParams(filters) {
    if (!filters.dates) {
      throw new Error('no dates');
    }
    var params = new URLSearchParams();
    for (var param of parseSearchFiltersToUrl(filters)) {
      params.append(param.filterName, param.filterValue);
    }
    if (params.get('bounds')) {
      // remove bounds if path
      if (params.get('path')) {
        params.delete('bounds');
      }
    }
    return params;
  }
}