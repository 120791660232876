import * as z from 'zod'
import { defaultPageSize } from './defaults'

const sortOptionsSchema = z.string()
export type SortOptions = z.infer<typeof sortOptionsSchema>

const searchBoundsSchema = z.object({
  northEast: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
  southWest: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
})

const geoPointSchema = z.object({
  lat: z.number(),
  lng: z.number(),
})

export type SimpleGeoPoint = z.infer<typeof geoPointSchema>

const searchPartySchema = z.object({
  adults: z.number(),
  childAges: z.number().array(),
})
export type SearchParty = z.infer<typeof searchPartySchema>

const areaSchema = z.object({
  lat: z.number(),
  lng: z.number(),
  isEmptyPoint: z.boolean(),
  rangeMiles: z.number().optional().nullable(),
})

const searchFiltersSchema = z.object({
  categoryIds: z.string().array().optional().nullable().default([]),
  page: z.number().optional().nullable(),
  limit: z.number().optional().nullable().default(defaultPageSize),
  sort: sortOptionsSchema.optional().nullable(),
  facets: z.string().array().optional().nullable().default([]),
  type: z.enum(['search', 'priceSearch']).optional().nullable(),
  hierarchyPath: z.string().optional().nullable(),
  area: areaSchema.optional().nullable(),
  within: z.number().optional().nullable(),
  bounds: searchBoundsSchema.optional().nullable(),
  dates: z
    .object({
      arrive: z.string(),
      depart: z.string(),
    })
    .optional()
    .nullable(),
  party: searchPartySchema.optional().nullable(),
  placename: z.string().optional().nullable(),
  q: z.string().optional().nullable(),
  priceRange: z
    .object({
      min: z.number().default(0),
      max: z.number().default(0),
    })
    .optional()
    .nullable(),
  base: z.string().optional().nullable().default('campsites'),
  fields: z.string().array().optional().nullable(),
})

export type SearchFilters = z.infer<typeof searchFiltersSchema>

const photoTransformsSchema = z.object({
  masterImage: z.string(),
})

export type PhotoTransforms = z.infer<typeof photoTransformsSchema>

const photoSchema = z.object({
  caption: z.string(),
  titleBy: z.string().optional(),
  titleAttribution: z.string().default(''),
  url: photoTransformsSchema,
})

export type Photo = z.infer<typeof photoSchema>

const moneySchema = z.object({
  amount: z.number(),
  amountMinor: z.number().int(),
  currency: z.string(),
})

const categorySchema = z.object({
  id: z.string(),
  rootSlug: z.string(),
  slug: z.string(),
  name: z.string(),
  order: z.number().optional(),
  isByo: z.boolean().nullable(),
})

const distanceSchema = z.object({
  km: z.number(),
  mi: z.number(),
})

const pitchtypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  primaryPhoto: photoSchema.nullable().optional(),
  categories: categorySchema.array(),
  price: moneySchema,
  pitchesToSell: z.number(),
})

const pitchtypeUndatedSchema = z.object({
  capacity: z.number().nullable().optional(),
  categories: categorySchema.array().optional().nullable(),
  isByo: z.boolean().nullable().optional(),
  leadPrice: moneySchema.optional().nullable(),
  leadPriceNights: z.number().optional().nullable(),
  name: z.string().optional(),
  personsIncluded: z.number().nullable().optional(),
  pitchtypeId: z.number().optional().nullable(),
})

const campsiteSearchResultSchemaBase = z.object({
  id: z.string(),
  name: z.string(),
  bookable: z.boolean(),
  availableToSearch: z.boolean(),
  nextOpen: z.string().optional().nullable(),
  slug: z.string(),
  status: z.enum(['bookable', 'free']),
  point: z.string(),
  hierarchyPath: z.string(),
  hierarchyText: z.string(),
  hierarchyStringShort: z.string(),
  hierarchyStringShortCountry: z.string(),
  primaryPhoto: photoSchema.nullable().optional(),
  rating: z.number(),
  rating10: z.number(),
  rateCount: z.number(),
  nutshells: z.string().array(),
  leadPrice: moneySchema.optional(),
  leadPriceNights: z.number().optional(),
  leadPriceAdults: z.number().optional().nullable(),
  categories: categorySchema.array(),
  distance: distanceSchema.optional(),
  firstAvailableDate: z.string().nullable().optional(),
  expectedValue: z.number().nullable().optional(),
})
export const campsiteSearchResultSchema = campsiteSearchResultSchemaBase.extend(
  {
    pitchtypes: pitchtypeUndatedSchema.array(),
  },
)
export type CampsiteSearchResult = z.infer<typeof campsiteSearchResultSchema>

export const campsitePriceSearchResultSchema =
  campsiteSearchResultSchemaBase.extend({
    pitchtypes: pitchtypeSchema.array(),
  })
export type CampsitePriceSearchResult = z.infer<
  typeof campsitePriceSearchResultSchema
>
const campsiteResult = z.union([
  campsitePriceSearchResultSchema,
  campsiteSearchResultSchema,
])
export type CampsiteResult = z.infer<typeof campsiteResult>

const hierarchyTrailSchema = z.object({
  name: z.string(),
  path: z.string(),
})
export type HierarchyTrail = z.infer<typeof hierarchyTrailSchema>

const searchCampsiteCategoryObject = z.object({
  id: z.number().nullable(),
  is_byo: z.boolean(),
  name: z.string(),
  root_slug: z.string(),
  slug: z.string().nullable(),
  sprite_class: z.string().nullable(),
})

export const searchFAQSchema = z.object({
  answer: z.string(),
  question: z.string(),
})
export type SearchFAQ = z.infer<typeof searchFAQSchema>

export const breadcrumbSchema = z.object({
  name: z.string(),
  url: z.string(),
  hierarchyPath: z.string().optional().nullable(),
  hierarchyLevel: z.number().optional().nullable(),
})
export type Breadcrumb = z.infer<typeof breadcrumbSchema>

export const searchCampsitesResultsSchema = z.object({
  campsites: campsiteSearchResultSchema.array(),
  pagination: z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    totalCount: z.number(),
  }),
  bookable: z.number().optional(),
  category: searchCampsiteCategoryObject.nullable().optional(),
  count: z.number(),
  engagementFacets: z
    .object({
      items: z.array(
        z.object({ name: z.string(), slug: z.string(), url: z.string() }),
      ),
      title: z.string(),
    })
    .optional(),
  facetHierarchy: z.record(z.number().int()),
  pagePart: z.string().nullable().optional(),
  maxPrice: z.number().optional(),
  title: z.string().optional(),
  point: geoPointSchema.optional(),
  path: z.string().optional(),
  heading: z.string().optional(),
  description: z.string().optional(),
  hierarchyTrail: hierarchyTrailSchema.array().optional(),
  hierarchyName: z.string().optional(),
  hierarchy: z.string().optional().nullable(),
  sortOptions: sortOptionsSchema.array(),
  sortOption: sortOptionsSchema,
  faq: searchFAQSchema.array().optional(),
  within: z
    .object({
      km: z.number(),
      mi: z.number(),
    })
    .optional(),
  breadcrumbs: breadcrumbSchema.array().optional(),
  engagementDestinations: z
    .object({
      title: z.string(),
      items: z
        .object({
          name: z.string(),
          url: z.string(),
          metaTitle: z.string().optional().nullable(),
        })
        .array()
        .optional(),
    })
    .optional(),
  engagementNarrowLinks: z
    .object({
      items: z
        .object({
          count: z.number(),
          name: z.string(),
          url: z.string(),
        })
        .array(),
      title: z.string(),
    })
    .nullable()
    .optional(),
  facetModels: z
    .record(
      z.object({
        name: z.string(),
        slug: z.string(),
        image: z.string().nullable().optional(),
        og_image: z.string().nullable().optional(),
      }),
    )
    .optional(),
  leadPrice: z.tuple([moneySchema, z.number()]).nullable().optional(),
  seoRedirect: z.string().nullable().optional(),
  pitchtypeFacets: z.string().array().optional(),
  bookableCount: z.number(),
  fullBookableCount: z.number().optional(),
  categoriesCounts: z.record(z.number()),
  hierarchyCounts: z.record(z.number()),
  keywordsCounts: z.record(z.number()),
})

export type SearchCampsitesResults = z.infer<
  typeof searchCampsitesResultsSchema
>

export const priceSearchCampsitesResults = searchCampsitesResultsSchema
  .omit({
    bookableCount: true,
    categoriesCounts: true,
    hierarchyCounts: true,
    keywordsCounts: true,
  })
  .extend({
    campsites: campsitePriceSearchResultSchema.array(),
  })

export type PriceSearchCampsitesResults = z.infer<
  typeof priceSearchCampsitesResults
>

export const searchCampsiteEndpoint = z
  .function()
  .args(searchFiltersSchema)
  .returns(z.promise(searchCampsitesResultsSchema))

export const priceSearchCampsiteEndpoint = z
  .function()
  .args(searchFiltersSchema)
  .returns(z.promise(priceSearchCampsitesResults))

// base context
export const searchContextSchema = z.object({
  errorMessage: z.string().optional(),
  error: z.any().optional(),
  results: searchCampsitesResultsSchema.optional(),
  filters: searchFiltersSchema,
  startTime: z.number().optional(),
  endTime: z.number().optional(),
  redirectToPath: z.string().optional(),
})
export type SearchContext = z.infer<typeof searchContextSchema>
