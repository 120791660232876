import { assign, createMachine, State } from 'xstate'
import { Interpreter } from 'xstate/lib/interpreter'
import { getDefaultSearchFilters } from '../defaults'
import { SearchContext, SearchFilters } from '../schemas'

export type SearchNonPriceEvent = {
  type: 'search'
  filters: SearchFilters
  searchUrl?: string
}
export type SearchPriceEvent = { type: 'priceSearch'; filters: SearchFilters }
export type SearchStateEvent =
  | SearchPriceEvent
  | SearchNonPriceEvent
  | { type: 'CLEAR_RESULTS' }

function getContextInitial(): SearchContext {
  return {
    filters: getDefaultSearchFilters(),
    error: undefined,
  }
}

export function getSearchMachine() {
  return createMachine<SearchContext, SearchStateEvent>(
    {
      id: 'search',
      initial: 'idle',
      context: getContextInitial(),
      on: {
        search: 'searching',
        priceSearch: 'priceSearching',
        CLEAR_RESULTS: {
          target: 'idle',
          actions: ['clearError', 'clearResults'],
        },
      },
      states: {
        idle: {},
        searching: {
          entry: assign({
            filters: (_, { filters }) => filters,
          }),
          invoke: {
            src: 'doSearch',
            onDone: [
              {
                actions: 'setRedirectPath',
                target: 'redirecting',
                cond: 'isSeoRedirect',
              },
              {
                target: 'results',
                cond: 'pathIsCorrect',
              },
              {
                actions: 'setRedirectPath',
                target: 'redirecting',
              },
            ],
            onError: [
              {
                // skip errors from abort
                target: 'idle',
                cond: (_context, event) => {
                  return event.data.cause?.code === 20
                },
              },
              {
                target: 'error',
                actions: assign({
                  error: ({ error }) => error,
                }),
              },
            ],
          },
          exit: ['setResults'],
        },
        priceSearching: {
          entry: assign({
            filters: (_, { filters }) => filters,
          }),
          invoke: {
            src: 'doPriceSearch',
            onDone: {
              target: 'results',
              actions: 'setResults',
            },
            onError: [
              {
                // skip errors from abort
                target: 'idle',
                cond: (_context, event) => {
                  return event.data.cause?.code === 20
                },
              },
              {
                target: 'error',
              },
            ],
          },
        },
        redirecting: {},
        results: {},
        error: {
          onEntry: ['setError', 'logError'],
          onExit: ['clearError'],
        },
      },
    },
    {
      actions: {
        clearError: assign((_) => {
          return { errorMessage: undefined }
        }),
        setError: assign((_, { data }: any) => {
          return { error: data, errorMessage: data.toString() }
        }),
        setResults: assign((_, { data }: any) => {
          return { results: data }
        }),
        clearResults: assign((_) => {
          return {
            results: undefined,
            filters: getDefaultSearchFilters(),
          }
        }),
        setRedirectPath: assign((_, { data }: any) => {
          return { redirectToPath: data.path }
        }),
        logError: (data) => {
          // eslint-disable-next-line no-console
          console.error(data)
        },
      },
      guards: {
        pathIsCorrect: ({ filters }, { data }: any) => {
          if (!data.path) return true
          return !!filters.hierarchyPath && filters.hierarchyPath === data.path
        },
        isSeoRedirect: ({ filters }, { data }: any) => {
          if (data.seoRedirect && filters.base) {
            const isSeoBase = !['search', 'map'].includes(filters.base)
            return isSeoBase
          }
          return false
        },
      },
      services: {
        doSearch: () => {
          throw new Error('doSearch Not Implemented')
        },
        doPriceSearch: () => {
          throw new Error('doSearch Not Implemented')
        },
      },
    },
  )
}

export type SearchState = State<SearchContext, SearchStateEvent>
export type SearchMachineInterpreter = Interpreter<
SearchContext,
SearchState,
SearchStateEvent
>
